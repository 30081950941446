/* eslint-disable no-use-before-define */
/* Import videojs. */
import videojs from 'video.js';

/* Import local libraries. */
import {
	get_out_view,
	dispatchCustomEvent,
	analyticsPayloadDict,
	dispatchOverlayInactiveEvent,
	isMobile,
} from '../util/helper.js';

/* Pause other players and fire desticky event if there's a floating one. */
export function pauseAndUnfloatOtherInstance(player) {
	videojs.getAllPlayers().forEach((player_other) => {
		if (player_other !== player) {
			player_other.pause();
			try {
				player_other.ima.pauseAd();
			} catch {}
			if (player_other.isFloating) {
				player_other.trigger('desticky');
			}
		}
	});
}

/* Player stickiness handling routine to be called on viewport changes, mainly scroll events. */
export function handle_sticky(
	container,
	placeholder,
	player,
	is_sticky,
	is_minimized,
	min_player_enabled,
	set_is_sticky,
	set_is_minimized,
	set_placeholder_style,
) {
	/* Exit if no player instance is given. */
	if (!player) {
		return;
	}
	/* Determine if video is playing. */
	const is_playing = !player.paused() || player?.ima?.controller?.sdkImpl?.isAdPlaying();
	/* Calculate stickiness variables. */
	const should_sticky = !is_sticky && is_playing && get_out_view(container);
	const should_desticky = is_sticky && !get_out_view(placeholder);

	if (should_sticky || should_desticky) {
		/* These few lines solve a bug that's caused by videojs's inline styling on '.vjs-text-track-cue'.
		 * 
		 * While they might seem hide subtitles completely, they actually just hide the current line, which
		 * means as soon as the next line comes around, everything is back to normal. With most subtitles, this
		 * will happen extremely quickly and should not stand out to users.
		 * 
		 * The reason it must be hidden completely instead of the CSS being overwritten is that typically when the
		 * player switches between being sticky and not sticky, the inline styles take a split second to catch up
		 * this is long enough that they are visibly in the wrong place for a moment, and the CSS can't be easily
		 * remedied as it is done through hard-coded inline pixel sizing on videojs's end.
		*/ 
		const textTrack = document.querySelector(".vjs-text-track-cue");
		if (textTrack) {
			textTrack.style = "display: none";
		}
	}

	const mobile = isMobile();
	const playerId = player.id();

	/* Getting stickied. */
	if (should_sticky) {
		/* Fetch computed styles from container. */
		const container_style = window.getComputedStyle(container);
		const c_style = [
			'height',
			'margin-top',
			'margin-bottom',
			'width',
			'margin-left',
			'margin-right',
		].reduce((acc, style_name) => {
			/* Conversion to number is applied in case of calculations. */
			acc[style_name] = Number(container_style.getPropertyValue(style_name).replace('px', ''));
			return acc;
		}, {});

		/* Assign placeholder style based on container style. */
		set_placeholder_style(
			Object.entries(c_style).reduce((acc, [key, value]) => {
				acc[key] = `${value}px`;
				return acc;
			}, {}),
		);

		/* Toggle stickiness. */
		player.isFloating = true;
		set_is_sticky(true);

		const analyticsPayload = analyticsPayloadDict[player.id()];
		if (analyticsPayload) {
			analyticsPayload.floatStatus = true;
			analyticsPayload.floatCount++;
		}

		if (min_player_enabled && mobile) {
			set_is_minimized(true);
			dispatchCustomEvent(player, 'playerMinimize');
		} else {
			set_is_minimized(false);
			dispatchCustomEvent(player, 'playerFloat');
		}

		handle_ads_resize(playerId);
		dispatchOverlayInactiveEvent(player);
	} else if (should_desticky) {
		/* Getting destickied. */
		/* Toggle stickiness. */
		player.isFloating = false;
		set_is_sticky(false);
		set_is_minimized(false);
		handle_ads_resize(playerId);
		const analyticsPayload = analyticsPayloadDict[player.id()];
		if (analyticsPayload) {
			analyticsPayload.floatStatus = false;
			analyticsPayload.unfloatTrigger = 'automatic';
		}
		if (min_player_enabled && mobile && is_minimized) {
			dispatchCustomEvent(player, 'playerUnminimize');
		} else {
			dispatchCustomEvent(player, 'playerUnfloat');
		}
	}
}

export function override_minimized_state(player, set_is_minimized, set_is_sticky, event_details) {
	const { isMinimized } = event_details;
	
	player.isFloating = isMinimized;
	set_is_minimized(isMinimized);
	set_is_sticky(isMinimized);
}

/* Ad container resizer for floating player. */
export function handle_ads_resize(playerId) {
	/**
	 * This gives us a smoother user experience during ads when the player is changing sizes.
	 * (Not showing the content's 'poster' under the ad container during resize for example.)
	 * This assumes the ad container is an IMA one and that it has a certain structure. It 
	 * will need to be updated if that changes.
	 */
	const adContainer = document.getElementById(`${playerId}_ima-ad-container`);
    const innerAdContainer = adContainer?.querySelector('div');
    if (innerAdContainer) {
      innerAdContainer.style.width = '100%';
      innerAdContainer.style.height = '100%';
    }
}
